<template>
    <div class="section index-section" key="insurance">
        <div class="section-title">
            <h3>2. Hast du eine Rechtsschutzversicherung?</h3>
            <p>Mit diesen Angaben hilfst Du uns, Dir die richtige Vorgehensweise zu empfehlen. Selbstverständlich entstehen Dir keine Kosten!</p>
        </div>
        <div class="form-container">
            <div class="form-card">
            <input type="radio" v-model="user.insurance" name="radio-1" value="Ja" id="question-3-1">
            <label for="question-3-1" v-on:click="next()"><span class="label-icon"><img src="@/assets/images/icons8-security-shield.svg"></span><span>Ja habe ich</span></label>
            </div>
            <div class="form-card">
            <input type="radio" v-model="user.insurance" name="radio-1" value="Nein" id="question-3-2">
            <label for="question-3-2" v-on:click="next()"><span class="label-icon"><img src="@/assets/images/icons8-delete-shield.svg"></span><span>Nein habe ich nicht</span></label>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'Insurance',
  computed: mapState({
    user: (state) => state.user,
  }),
  methods: {
    next() {
      this.$emit('next');
    }
  },
  created() {
    this.user.insurance = '';
  }
}
</script>